import { makeStyles } from "@material-ui/core"
import { graphql } from "gatsby"
import * as React from "react"
import { ReactElement } from "react"
import { Download, DownloadElement } from "../components/Download"
import Layout from "../components/Layout"

const useStyles = makeStyles({
    title: {
        fontWeight: 'bold',
        fontSize: '1.5em',
        marginLeft: '10px'
    }
})

export const query = graphql`
  query PLDownloadQuery {
    allContentXlsxPlDownload {
      nodes {
        title
        url
      }
    }
  }
`


interface Nodes {
    nodes: Array<DownloadElement>
}

interface DataSheet {
    allContentXlsxPlDownload: Nodes
}
export interface Props {
    data: DataSheet
}

const Pobieranie = ({data: {allContentXlsxPlDownload: {nodes}}}: Props): ReactElement => {
    const styles = useStyles()
    return (
        <Layout actualTab="Oferta">
            <>
                <p className={styles.title}>Do pobrania</p>
                <Download files={nodes} />
            </>
        </Layout>
    )
}

export default Pobieranie