import { makeStyles } from "@material-ui/core"
import * as React from "react"

const useStyles = makeStyles({
    wrapper: {
        paddingLeft: '20px'
    },
    link: {
        fontSize: '2em',
        textDecoration: 'none',
        color: 'black'
    }
})

export interface DownloadElement {
    title: string
    url: string
}

interface Props {
    files: Array<DownloadElement>
}

export const Download = ({files}: Props) => {
    const styles = useStyles()
    return <div className={styles.wrapper}>
        {files.map(({title, url}: DownloadElement) => {
            return <a href={url} target="_blank" className={styles.link}>{title}</a>
        })}
    </div>
}